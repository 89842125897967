<template>
  <section class="signup">
    <div class="container-new">
      <div class="signup__image"></div>
      <div class="signup__form">
        <h2>{{ $t('Sign up and join our world of books!') }}</h2>
        <validation-observer
          v-slot="{ handleSubmit, invalid }"
          ref="form"
          tag="div"
        >
          <form @submit.prevent="handleSubmit(submit)">
            <validation-provider
              v-slot="{ errors }"
              class="form-group form-group--horizontal"
              tag="div"
              :name="$t('User name')"
              vid="username"
            >
              <label
                for="username"
                class="form-label"
                v-text="$t('User Name')"
              ></label>
              <div
                class="form-validation"
                :class="{ 'is-invalid': errors.length }"
              >
                <input
                  id="username"
                  v-model="form.username"
                  type="text"
                  :placeholder="$t('Jonny')"
                  class="form-control form-input--underline"
                />
                <span v-if="errors.length" v-text="errors[0]"></span>
              </div>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="form-group form-group--horizontal"
              tag="div"
              rules="required|email"
              :name="$t('Email')"
              vid="email"
            >
              <label
                for="email"
                class="form-label"
                v-text="$t('Email')"
              ></label>
              <div
                class="form-validation"
                :class="{ 'is-invalid': errors.length }"
              >
                <input
                  id="email"
                  v-model="form.email"
                  type="text"
                  :placeholder="$t('john.pitterson@gmail.com')"
                  class="form-control form-input--underline"
                />
                <span v-if="errors.length" v-text="errors[0]"></span>
              </div>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="form-group form-group--horizontal"
              tag="div"
              rules="required|min:8|confirmed:password_confirmation"
              :name="$t('Password')"
              vid="password"
            >
              <label
                for="password"
                class="form-label"
                v-text="$t('Password')"
              ></label>
              <div
                class="form-validation"
                :class="{ 'is-invalid': errors.length }"
              >
                <input
                  id="password"
                  v-model="form.password"
                  autocomplete="new-password"
                  type="password"
                  :placeholder="$t('min 8 symbols')"
                  class="form-control form-input--underline"
                />
                <span v-if="errors.length" v-text="errors[0]"></span>
              </div>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="form-group form-group--horizontal"
              tag="div"
              name="password_confirmation"
            >
              <label
                for="password-confirmation"
                class="form-label"
                v-text="$t('Confirm password')"
              ></label>
              <div class="form-validation">
                <input
                  id="password-confirmation"
                  v-model="form.password_confirmation"
                  type="password"
                  :placeholder="$t('retype the password you have just created')"
                  class="form-control form-input--underline"
                />
                <span v-if="errors.length" v-text="errors[0]"></span>
              </div>
            </validation-provider>
            <div class="signup__terms">
              <i18n path="accept-policy" tag="p">
                <template v-slot:terms>
                  <router-link class="signup__link" :to="$localePath('terms')">
                    {{ $t('Terms and Conditions') }}
                  </router-link>
                </template>
                <template v-slot:policy>
                  <router-link
                    :to="$localePath('policy')"
                    class="signup__link mb-4"
                  >
                    {{ $t('Privacy policy') }}
                  </router-link>
                </template>
              </i18n>
              <div class="button-group">
                <button
                  class="btn btn--primary btn--signup mr-2"
                  :disabled="invalid || loading"
                  :class="{ 'btn--disabled': invalid || loading }"
                >
                  {{ $t('Sign Up') }}
                </button>
                <div class="mr-1">
                  <span>
                    {{ $t('Already registered?') }}
                  </span>
                  <router-link
                    class="signup__link t-nowrap"
                    :to="$localePath('login')"
                  >
                    {{ $t('Log In') }}
                  </router-link>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal">
      <h5 slot="title" class="mb-0" style="white-space: pre-wrap">
        {{ $t('Thank you for joining us in eXandria!') }}
      </h5>
      <div slot="content" class="modal__content">
        <div>
          <img src="@/assets/img/user_registered.svg" alt="" />
        </div>
        <router-link
          class="btn btn--primary"
          :to="{ name: 'profile' }"
          v-text="$t('Go to profile')"
        ></router-link>
      </div>
    </modal>
  </section>
</template>

<i18n src="./translations.json"></i18n>

<script>
import authService from '@/services/authService';
import Response from '@/http/Response';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Modal from '@/components/modal/Modal';

export default {
  components: {
    Modal,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      form: {
        email: '',
        first_name: '',
        password: '',
        username: '',
      },
      loading: false,
      response: null,
      isModalVisible: false,
    };
  },

  methods: {
    async submit() {
      this.loading = true;
      this.response = null;

      try {
        await authService.register(this.form);

        this.isModalVisible = true;
        this.resetForm();
      } catch ({ response }) {
        this.response = new Response({ ...response, success: false });

        if (this.response.hasValidationErrors()) {
          this.$refs.form.setErrors(this.response.getValidationErrors());
        }
      }
      this.loading = false;
    },

    resetForm() {
      this.form = {
        email: '',
        first_name: '',
        password: '',
      };

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },

    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import './../login/login.scss';
@import './signup.scss';
</style>
